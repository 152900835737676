<template>
  <div class="work-bench-right">
    <div class="bench-content-wrapper" v-if="showBench">
      <div class="bench-head">
        <div class="bench-head-avatar">
          <img :src="mineInfo.avatar" alt="" />
        </div>
        <div class="bench-head-info">
          <router-link
            to="dataDetailsLayout"
            class="name text-overflow"
            style="font-size: 15px"
            >{{ mineInfo.nickname }}
          </router-link>
          <p class="account">抖音号：{{ mineInfo.unique_id }}</p>
          <p class="info">
            <span
              >性别：{{
                mineInfo.gender == 0
                  ? "男"
                  : mineInfo.gender == 1
                  ? "女"
                  : "未知"
              }}</span
            >
            <span v-if="mineInfo.province">地区：{{ mineInfo.city }}</span>
          </p>
        </div>
      </div>
      <div class="bench-summary">
        <div class="summary-left">简介：</div>
        <div class="summary-content">
          {{ mineInfo.signature }}
        </div>
      </div>
      <div class="bench-ranking">
        <div
          class="ranking-item"
          v-if="
            mineInfo.author_fans_rank.type !== '' &&
            mineInfo.author_fans_rank.rank !== ''
          "
        >
          <div class="rank-icon"></div>
          <span class="text"
            >涨粉排行
            {{
              mineInfo.type == 1
                ? "日"
                : mineInfo.type == 2
                ? "周"
                : mineInfo.type == 3
                ? "月"
                : ""
            }}榜第<span class="num">{{ mineInfo.rank }}</span
            >名</span
          >
        </div>
      </div>
      <div class="bench-data-overview">
        <div class="data-title">数据概况</div>
        <div class="update-data">
          <div class="manualRefresh iconfont" @click="getUpdateData">&#xe655; 数据更新</div>
          <div>数据更新时间: {{mineInfo.update_time}}</div>
        </div>
        <div class="data-list">
          <div class="data-item">
            <div class="item-left">
              <p class="item-hang1">粉丝数量</p>
              <p
                class="item-num big-green"
                style="color: #20c997"
                v-if="mineInfo.follower_count"
              >
                {{ Math.floor(mineInfo.follower_count / 1000) / 10 + "W" }}
              </p>
            </div>

            <div class="item-center">
              <p class="item-hang1">带货口碑</p>
              <p class="item-num" v-if="mineInfo.reputation_score > 4.7">
                {{ mineInfo.reputation_score
                }}<span
                class="setbackground-color"
                  style="
                    font-size: 14px;
                    margin-left: 10px;
                    background: red;
                    color: #fff;
                  "
                  >高</span
                >
              </p>
              <p class="item-num" v-else-if="mineInfo.reputation_score > 4.2 && mineInfo.reputation_score < 4.7">
                    {{ mineInfo.reputation_score
                }}<span
                class="setbackground-color"
                  style="
                    font-size: 14px;
                    margin-left: 10px;
                    background: red;
                    color: #fff;
                  "
                  >中</span
                >
              </p>
              <p class="item-num" v-if="mineInfo.reputation_score < 4.2">
                    {{ mineInfo.reputation_score
                }}<span
                class="setbackground-color"
                  style="
                    font-size: 14px;
                    margin-left: 10px;
                    background: red;
                    color: #fff;
                  "
                  >低</span
                >
              </p>
            </div>

            <div class="item-right">
              <p class="item-hang1">视频数</p>
              <p class="item-num">
                {{
                  mineInfo.history_aweme_count !== ""
                    ? mineInfo.history_aweme_count
                    : "--"
                }}
              </p>
            </div>
          </div>
          <div class="data-item">
            <div class="item-left" style="margin-right: 20px">
              <p class="item-hang1">总点赞数</p>
              <p class="item-num">
                {{
                  mineInfo.total_favorited !== ""
                    ? mineInfo.total_favorited
                    : "--"
                }}
              </p>
            </div>
            <div class="item-comments">
              <p class="item-hang1">总评论量</p>
              <p class="item-num">
                {{
                  mineInfo.total_comment !== "" ? mineInfo.total_comment : "--"
                }}
              </p>
            </div>
            <div class="item-share">
              <p class="item-hang1">总转发量</p>
              <p class="item-num">
                {{ mineInfo.total_share !== "" ? mineInfo.total_share : "--" }}
              </p>
            </div>
            <div class="item-right"></div>
          </div>
        </div>
      </div>
      <div class="bench-data-oper">
        <div class="btn-air-32" v-if="bid > 0 && isShow" @click="toList()">
          切换账号
        </div>
      </div>
    </div>
    <div class="bench-nodata-wrapper" v-else>
      <div class="bench-nodata-img">
        <img src="../../assets/img/image/img_wuzhanghao.png" alt="" />
      </div>
      <p class="bench-nodata-text"></p>
    </div>
  </div>
</template>

<script>
import { showAuthorInfo, getUpdateInfo } from "@/utils/apis";
export default {
  props: ["author_id","is_date","date","type"],
  data() {
    return {
      bid: 0,
      showBench: false,
      showCurrentBtn: true,
      isShow: false,
      approvalStatus: "", //审核状态
      mineInfo: {},
    };
  },
  mounted() {
    this.getAuthorInfo();
  },
  methods: {

    // 获取播主个人介绍等信息
    getAuthorInfo() {
      let param={
       userid: this.author_id,
       is_date:this.is_date,
       date:this.date,
       type:this.type
      }
      showAuthorInfo(param).then((res) => {
        this.mineInfo = res.data;
        if (localStorage.getItem('rank')) {
            this.mineInfo.rank = localStorage.getItem('rank');
        }
        this.showBench = true;
      });
    },
    // 手动刷新播主信息
    getUpdateData(){
      let param={
       userid: this.author_id,
       is_date:this.is_date,
       date:this.date,
       type:this.type
      }
        getUpdateInfo(param).then((res)=>{
            
            this.$message({
                type:'success',
                message: res.msg
            })
             this.getAuthorInfo();
        })
    }
  },
};
</script>

<style scoped lang="scss">
.work-bench-right {
  width: 360px;
  background: #fff;
  margin-left: 25px;
}

.bench-nodata-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
}

.bench-nodata-wrapper .bench-nodata-img {
  width: 200px;
}

.bench-nodata-wrapper .bench-nodata-img img {
  max-width: 100%;
}

.bench-nodata-wrapper .bench-nodata-text {
  margin: 30px 0 40px;
  color: #666;
  font-size: 14px;
}

.bench-content-wrapper {
  padding: 15px;
  position: relative;
}

.bench-content-wrapper .bench-head {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.bench-content-wrapper .bench-head-avatar {
  width: 80px;
  height: 80px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bench-content-wrapper .bench-head-avatar img {
  max-width: 100%;
  max-height: 100%;
}

.bench-content-wrapper .bench-head-info {
  width: 1%;
  flex: 1;
  line-height: 1;
  margin-left: 15px;
  color: #999;
  font-size: 14px;
}

.bench-content-wrapper .bench-head-info .name {
  color: #333;
  font-size: 16px;
}

.bench-content-wrapper .bench-head-info .name:hover {
  color: #20c997;
}

.bench-content-wrapper .bench-head-info .account {
  margin-top: 10px;
}

.bench-content-wrapper .bench-head-info .info {
  margin-top: 10px;
}

.bench-content-wrapper .bench-head-info .info span {
  display: inline-block;
  margin-right: 6px;
}

.bench-content-wrapper .bench-head-info .info span:last-of-type {
  margin-right: 0;
}

.bench-content-wrapper .bench-summary {
  margin-top: 20px;
  display: flex;
}

.bench-content-wrapper .bench-summary .summary-content {
  width: 1%;
  flex: 1;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.bench-content-wrapper .bench-ranking {
  margin-top: 20px;
}

.bench-content-wrapper .bench-ranking .ranking-item {
  display: inline-flex;
  align-items: center;
  background: #ff1616;
  color: #fff;
  height: 24px;
  line-height: 24px;
  border-radius: 12px;
  padding: 0 15px 0 2px;
  margin-bottom: 10px;
}

.bench-content-wrapper .bench-ranking .ranking-item:last-of-type {
  margin-bottom: 0;
  background: #ffb624;
}

.bench-content-wrapper .bench-ranking .ranking-item .rank-icon {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  background: #fff url("../../assets/img/image/wb-hg-icon.png") no-repeat center;
}

.bench-content-wrapper .bench-ranking .ranking-item .text {
  margin-left: 10px;
}

.bench-content-wrapper .bench-ranking .ranking-item .text .num {
  margin: 0 4px;
}

.bench-content-wrapper .bench-data-overview {
  margin-top: 40px;
}

.bench-content-wrapper .bench-data-overview .data-title {
  font-size: 16px;
}

.bench-content-wrapper .bench-data-overview .manualRefresh{
    position: absolute;
    right: 0;
    top: -25px;
    cursor: pointer;
    
}

.bench-content-wrapper .bench-data-overview .update-data {
  position: absolute;
  top: 44.5%;
  right: 4%;
  color: #999;
}

.bench-content-wrapper .data-list {
  /*font-size: 12px;*/
  color: #999;
}

.bench-content-wrapper .data-list .data-item {
  display: flex;
  flex-wrap: wrap;
}

.bench-content-wrapper .data-list .item-left {
  width: 35%;
  margin-top: 15px;
  flex: 1;
}
.bench-content-wrapper .data-list .item-center {
  width: 35%;
  margin-top: 15px;
  margin-left: 20px;
  flex: 1;
  .setbackground-color{
      display: inline-block;
      width: 20px;
      height: 20px;
      text-align: center;
      border-radius: 3px;
  }
}

.bench-content-wrapper .data-list .item-right {
  width: 23%;
  margin-top: 15px;
  text-align: right;
}

.bench-content-wrapper .data-list .item-share {
  position: absolute;
  top: 93%;
}
.bench-content-wrapper .data-list .item-comments {
  position: absolute;
  bottom: 10%;
  right: 3%;
}

.bench-content-wrapper .data-list .item-num {
  font-size: 16px;
  color: #333;
}

.big-green {
  color: #20c997;
}

.bench-content-wrapper .data-list .data-item:first-of-type {
  padding-bottom: 15px;
  border-bottom: 1px solid #ededed;
}

.bench-content-wrapper .data-list .data-item:first-of-type .item-num {
  font-size: 20px;
}

.bench-content-wrapper .bench-data-oper {
  margin-top: 40px;
  text-align: center;
}
</style>